import { useScenarioHost } from '@axinom/mosaic-fe-samples-host';
import {
  UserAuthConfig,
  UserServiceConfig,
  UserServiceProvider,
  useUserService,
} from '@axinom/mosaic-user-auth';
import { SignInResponseCode } from '@axinom/mosaic-user-auth-utils';
import { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Divider,
  Form,
  FormProps,
  Header,
  Label,
  Segment,
} from 'semantic-ui-react';
import { VAR_KEY_AX_USER_ACCESS_TOKEN } from '../../../common/types/well-known-variable-keys';
import { ScenarioKey } from '../../../scenario-registry';

const SCENARIO_KEY_SIGN_IN_WITH_AXAUTH: ScenarioKey = 'sign-in-with-axauth';

export const SignInWithAxAuthContainer: React.FC = () => {
  const { activeProfile } = useScenarioHost();

  const userAuthConfig: UserAuthConfig = {
    userAuthBaseUrl: activeProfile.userAuthBaseURL,
    tenantId: activeProfile.tenantId,
    environmentId: activeProfile.environmentId,
    applicationId: activeProfile.applicationId,
  };

  const userServiceConfig: UserServiceConfig = {
    userServiceBaseUrl: activeProfile.userServiceBaseURL,
  };

  return (
    <>
      <Segment basic>
        <Header size="huge">Sign-In With Email/Password</Header>
        <Header size="small">
          Required Services:
          <Label>ax-user-service</Label>
          <Label>ax-auth-service</Label>
        </Header>

        <Divider />

        <Container fluid>
          <p>
            This scenario demonstrates how to Sign-In with an Email/Password
            that has been used previously to Sign-Up for an account.
          </p>
        </Container>

        <Divider />

        <UserServiceProvider
          userAuthConfig={userAuthConfig}
          userServiceConfig={userServiceConfig}
        >
          <SignInWithAxAuth></SignInWithAxAuth>
        </UserServiceProvider>
      </Segment>
    </>
  );
};

export const SignInWithAxAuth: React.FC = () => {
  const { activeProfile, logger, setVariable } = useScenarioHost();

  const { isDirectSignInConfigured, signInWithCredentials, getToken } =
    useUserService();

  useEffect(() => {
    (async () => {
      if (!(await isDirectSignInConfigured())) {
        logger.warn(
          'scenario validating the config on-load',
          `output: An AxAuth IDP is not configured for the Application [${activeProfile.applicationId}]`,
        );
      }
    })();
  }, [activeProfile.applicationId, isDirectSignInConfigured, logger]);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const signIn = async (
    _event: React.FormEvent<HTMLFormElement>,
    _data: FormProps,
  ): Promise<void> => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      logger.warn(
        `calling [signIn]`,
        `output: Incorrect email format. Please try again`,
      );
    } else if (password.length === 0) {
      logger.warn(
        `calling [signIn]`,
        `output: Password is required to Sign-In. Please try again`,
      );
    } else {
      // Sign-In with email/password. If successful, this save an HTTP Cookie, but will not generate an access-token.
      // Access-Tokens will get generated by calling getToken()
      const signInResponse = await signInWithCredentials({
        email,
        password,
      });

      if (signInResponse.code === SignInResponseCode.SUCCESS) {
        // Now we can call getToken to generate an access-token and log it.
        const tokenResponse = await getToken();

        if (tokenResponse.code === 'SUCCESS') {
          setVariable(
            VAR_KEY_AX_USER_ACCESS_TOKEN,
            tokenResponse.userToken?.accessToken,
            `set via scenario: ${SCENARIO_KEY_SIGN_IN_WITH_AXAUTH}`,
          );
          logger.log(`calling [signIn]`, 'output:', tokenResponse);
        } else {
          logger.error(`calling [signIn]`, 'output:', tokenResponse);
        }
      } else {
        logger.error(`calling [signIn]`, 'output:', signInResponse);
      }
    }
  };

  return (
    <>
      <Form onSubmit={signIn}>
        <Form.Group>
          <Form.Input
            width={4}
            icon="user"
            iconPosition="left"
            placeholder="Email"
            label="Email"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
        </Form.Group>

        <Form.Group>
          <Form.Input
            width={4}
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            type="password"
            label="Password"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
        </Form.Group>

        <Button type="submit" primary>
          Sign In
        </Button>
      </Form>
    </>
  );
};
